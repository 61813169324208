<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
            <router-link to="periodic-activity-monitoring" :class="'btn btn-primary text-light mr-2'">{{ $t('research_manage.periodic_activity_monitoring') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                 <b-col lg="12" sm="12">
                  <template>
                        <div style="font-size:12px; background-color: #337982; padding:6px">
                            <h4 class="text-white text-center">{{ $t('research_manage.gantt_chart') }}</h4>
                        </div>
                    </template>
                 </b-col>
              </b-row>
              <!-- <b-row >
                <b-col md="12">
                  <b-overlay>
                    <b-row>
                      <b-col md="12">
                        <iq-card>
                          <template>
                            <g-gantt-chart
                              :chart-start="myChartStart"
                              :chart-end="myChartEnd"
                            >
                              <g-gantt-row
                                v-for="row in rows"
                                :key="row.label"
                                :label="row.label"
                                :bars="row.bars"
                                bar-start="myStart"
                                bar-end="myEnd"
                              />
                            </g-gantt-chart>
                          </template>
                        </iq-card>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-col>
              </b-row> -->
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { periodicActivityMonitoringShow } from '../../api/routes'
// import { GGanttChart, GGanttRow } from 'vue-ganttastic'
// import ExportPdf from './export_pdf_details'

export default {
    components: {
    //   GGanttChart, GGanttRow
    },
    created () {
      if (this.$route.query.id) {
          this.getActivityData()
      }
    },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    // return {
    //   loading: false,
    //   activityData: [],
    //   myChartStart: '2020-03-01',
    //   myChartEnd: '2020-03-05',
    //   rows: [
    //     {
    //       label: 'My row #1',
    //       bars: [
    //         {
    //           myStart: '2020-03-01',
    //           myEnd: '2020-03-02'
    //         }
    //       ]
    //     },
    //     {
    //       label: 'My row #2',
    //       bars: [
    //         {
    //           myStart: '2020-03-02',
    //           myEnd: '2020-03-02'
    //         },
    //         {
    //           myStart: '2020-03-01',
    //           myEnd: '2020-03-03'
    //         }
    //       ]
    //     }
    //   ]
    // }
  },
  computed: {
  },
  methods: {
    async getActivityData () {
        this.loading = true
        const result = await RestApi.getData(agriResearchServiceBaseUrl, `${periodicActivityMonitoringShow}/${this.$route.query.id}`)
        if (result.success) {
            this.activityData = result.data
        }
        this.loading = false
    },
    getRelationalData (data) {
          const organization = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(data.org_id))
          return Object.assign({}, data,
            {
              org_name: organization !== undefined ? organization.text_en : '',
              org_name_bn: organization !== undefined ? organization.text_bn : ''
            }
          )
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
